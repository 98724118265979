.dialog-left{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.dialog-right{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.divider{
    height: 300px;
    width: 1px;
    background-color: #d9d9d9;
}
.c-dialog-row{
    gap: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}