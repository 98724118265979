@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');
body,html {
  min-width: 1600px;
  /* min-height: 1000px; */
  margin: 0;
  font-family: 'Pretendard', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Pretendard', sans-serif;
}
