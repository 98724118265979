.login-content{
    display: flex;

}
.login-bg-img-container{
    width: 600px;
    height: 100vh;
    overflow: hidden;
}
.login-bg-img{
    height: 100%;
}