.notice-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.notice-create-row{

}
.notice-search-row{
display: flex;
flex-direction: row;
gap: 30px;
}