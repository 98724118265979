.dialog-col{
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}
.divider{
    
    width: 1px;
    background-color: #d9d9d9;
}
.phone-row{
    display: flex;
    gap: 10px;
    align-items: center;
}