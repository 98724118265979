.company-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.company-create-row{

}
.company-search-row{
display: flex;
flex-direction: row;
gap: 30px;
}