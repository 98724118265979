.content{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.create-row{
display: flex;
}
.search-row{
display: flex;
flex-direction: row;
gap: 30px;
}